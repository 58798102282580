import type { Reducer, AnyAction } from 'redux';

import {
  SET_APP_CONNECTION_HEALTH,
  SET_ENGINE_HEALTH,
  SET_VERSION_HEALTH,
} from '../actionTypes';

const isBrowser = process.env.BROWSER;

export interface HealthState {
  isAppConnectionHealthy: boolean;
  isEngineHealthy: boolean;
  isAppDBHealthy: boolean;
  isLicenseGood:
    | {
        email: string;
        entitlements: {
          description: string;
          label: string;
          name: string;
        }[];
        expiration: string;
        id: string;
        isBeforeExpiration: boolean;
        isBeforeWarning: boolean;
        organization: string;
        type: string;
        user: string;
        version: string;
        warning: string;
      }
    | false;
  isEngineVersionGood: {
    engineVersion: string;
    uiVersion: string;
    valid: boolean;
  };
  isReportsEnabledAndActive: {
    active: boolean;
    enabled: boolean;
  };
}

export const defaultState: HealthState = {
  // Engine Health Status Default Props
  isAppConnectionHealthy: isBrowser ? window.App.isAppConnectionHealthy : true,
  isEngineHealthy: isBrowser
    ? window.App.isEngineHealthy
    : !!global.HEALTH_CHECK,
  isAppDBHealthy: isBrowser ? window.App.isAppDBHealthy : !!global.APPDB_CHECK,
  isLicenseGood: isBrowser ? window.App.isLicenseGood : global.LICENSE_CHECK,
  isEngineVersionGood: isBrowser
    ? window.App.isEngineVersionGood
    : global.VERSION_CHECK,
  isReportsEnabledAndActive: isBrowser
    ? window.App.isReportsEnabledAndActive
    : global.REPORTS_CHECK,
};

const reducer: Reducer<HealthState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * APP HEALTH STATUS REDUCERS
     */
    case SET_APP_CONNECTION_HEALTH:
      return {
        ...state,
        isAppConnectionHealthy: action.health,
      };

    /**
     * *************************************************************************
     * ENGINE HEALTH STATUS REDUCERS
     */
    case SET_ENGINE_HEALTH:
      return {
        ...state,
        isEngineHealthy: action.health,
        isReportsEnabledAndActive: action.reports,
        isAppDBHealthy: action.appdb,
      };

    case SET_VERSION_HEALTH:
      return {
        ...state,
        isEngineVersionGood: action.health,
      };

    default:
      return state;
  }
};

export default reducer;
