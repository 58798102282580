// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root .VbmBK{display:flex}:root .VbmBK .rIzeD{background-color:#fff;border-radius:100%;display:block;height:fit-content;margin-left:.5rem;width:fit-content}:root .VbmBK .rIzeD .kAZVd{font-weight:700}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWrapper": `VbmBK`,
	"countdownWrapper": `rIzeD`,
	"countdownRemaining": `kAZVd`
};
module.exports = ___CSS_LOADER_EXPORT___;
