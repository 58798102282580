import { createSlice } from '@reduxjs/toolkit';

import type {
  ListConfigsActionParams,
  GetConfigurationsResponse,
  SaveConfigsActionParams,
  PatchConfigurationsResponse,
  SystemConfiguration,
  StatusEntity,
} from '@models';
import { nullStatusEntity } from '@models';

import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from './common';

const entities = {
  getConfigurations: createEntityAction<
    ListConfigsActionParams,
    GetConfigurationsResponse
  >({
    stateKey: 'configurations',
    servicePath: () => `/service/configurations`,
  }),
};

export const { getConfigurations } = getEntityActions(entities);

export const saveSystemConfigurations = createEntityAction<
  SaveConfigsActionParams,
  PatchConfigurationsResponse
>({
  verb: 'patch',
  stateKey: 'configurations',
  servicePath: () => `/service/configurations`,
  toastOnFailure: (_, error) => (
    <span>
      <p>
        An error occurred while attempting to update system configuration
        settings.
      </p>
      {error?.message}
    </span>
  ),
  toastOnSuccess: () => (
    <span>System configuration settings were successfully updated.</span>
  ),
  getToastId: () => 'system-config-save',
}).thunk;

export interface ConfigState {
  configurations: StatusEntity<SystemConfiguration[]>;
}

export const initialState: ConfigState = {
  configurations: nullStatusEntity,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);

    builder
      // Save configurations
      .addCase(saveSystemConfigurations.pending, state => {
        state.configurations.status = 'updating';
      })
      .addCase(saveSystemConfigurations.fulfilled, (state, action) => {
        state.configurations.data = action.payload.body.data;
        state.configurations.status = 'ready';
      })
      .addCase(saveSystemConfigurations.rejected, (state, action) => {
        state.configurations.status = 'error';
        state.configurations.error = action.payload || action.error;
      });
  },
});

export default configSlice.reducer;
