import { combineReducers } from '@reduxjs/toolkit';
import _merge from 'lodash/merge';

import updateDbState from '@services/utils/update_db_state';
import { SET_APP_STATE_RESET, SET_APP_STATE_UPDATE } from '@redux/actionTypes';

import accounts from '../accounts.slice';
import alp from '../alp.slice';
import applications from '../applications.slice';
import config from '../config.slice';
import images from '../images.slice';
import inventories from '../inventories.slice';
import modals from '../modals.slice';
import reports from '../reports.slice';
import statistics from '../statistics.slice';
import feeds from '../system/feeds.slice';
import health from '../system/health.slice';
import services from '../system/services.slice';

import markedActions from './marked_actions.json';
import app from './reducer-app';
import artifactAnalysis from './reducer-artifact-analysis';
import auth from './reducer-auth';
import dashboard from './reducer-dashboard';
import events from './reducer-events';
import graphqlSpec from './reducer-graphql-spec';
import imageSelection from './reducer-image-selection';
import policyEditor from './reducer-policy-editor';
import policyManager from './reducer-policy-manager';
import subscriptions from './reducer-subscriptions';
import swaggerSpec from './reducer-swagger-spec';
import system from './reducer-system';

const allReducers = combineReducers({
  accounts,
  auth,
  alp,
  swagger: combineReducers({
    spec: swaggerSpec,
  }),
  graphql: graphqlSpec,
  artifacts: artifactAnalysis,
  image: combineReducers({
    selection: imageSelection,
  }),
  policy: combineReducers({
    manager: policyManager,
    editor: policyEditor,
  }),
  applications,
  events,
  subscriptions,
  system,
  dashboard,
  app,
  reports,
  modals,
  images,
  inventories,
  statistics,
  health,
  feeds,
  services,
  config,
});

// The root reducer provides an entry point for invoking every reducer contained
// within `allReducers`.
//
// If no state data is provided, the default state object in each reducer is
// used instead, thereby allowing us to reset *every* key in the Redux state
// store back to its initial value.
//
// This is especially useful on login / logout as it ensures that all Redux data
// items associated with a session are properly flushed before the next session
// is initialized.
//
// The root reducer has two custom action interceptors: the first rebases the
// entire state object, and the second allows one or more top-level state items
// to be updated directly with all others remaining unchanged. Both interceptors
// are described below.
const rootReducer = (state, action: any = {}) => {
  let s = state;

  // SET_APP_STATE_RESET: Leverages the behavior described above in order to
  // reset the entire state object. However, any top-level items (optionally)
  // provided by the `action.conf` object will be assigned into the state object
  // after the rebase is complete. This is useful in scenarios where we don't
  // want the user to lose their authentication data (and be logged out). The
  // best practice for preserving a top-level item is to first copy it using the
  // object spread operator before passing it back. For example:
  //
  // appStateReset({ auth: { ...auth } });

  if (action.type === SET_APP_STATE_RESET) {
    // Trigger a reset for the state object
    s = {};
  }

  let newState = allReducers(s, action);

  if (action.type === SET_APP_STATE_RESET && typeof action.conf === 'object') {
    // Merge items we wanted to keep after the base state is returned
    const m = { ...newState };
    Object.keys(action.conf).forEach(item => {
      if (newState[item]) {
        m[item] = _merge({}, newState[item], action.conf[item]);
      }
    });
    newState = m;
  }

  // Note that the top-level key *must* correspond to one that already exists
  // inside the state object - the reset operation cannot be used to inject
  // arbitrary items into state!
  //
  // SET_APP_STATE_UPDATE: Replaces the contents of any top-level state entry
  // with the value of an equivalent key provided by the `action.conf` object.
  // This operation can be used to completely replace an existing item (or
  // items) when updated data is sent from the service to the client via socket
  // message. For example:
  //
  // appStateUpdate({ system: { ...system, ...updatedSystem } });

  if (action.type === SET_APP_STATE_UPDATE && typeof action.conf === 'object') {
    const m = { ...newState };
    Object.keys(action.conf).forEach(item => {
      if (newState[item]) {
        m[item] = action.conf[item];
      }
    });
    newState = m;
  }

  // Update the DB state using socket messages
  //
  // Looks to marked_actions.json to determine if anything should be saved
  // based on the action type most recently dispatched. If so, passes through
  // the current reduced state object, the action, and the config necessary
  // to communicate what should be saved where in the DB.
  const canUpdateDbState =
    process.env.BROWSER &&
    window &&
    window.sock &&
    window.App?.account?.sessionID &&
    window.App?.isAuthenticated;
  if (newState && canUpdateDbState) {
    // Check if this action has been marked to store items to the db state
    const marked = markedActions.find(item => item.actionType === action.type);
    if (marked) updateDbState(newState, action, marked);
  }

  return newState;
};

export default rootReducer;
